<template>
  <div class="impulse-input">
    <impulse-input-component
      :frames-total="impulseInputState.frames.total"
      :frames-correct="impulseInputState.frames.correct"
      :frames-incorrect="impulseInputState.frames.incorrect"
      :bar-duration="impulseInputState.barDuration"
      :side="impulseInputState.side.toLowerCase()"
      :left-message-class="impulseInputState.leftText"
      :right-message-class="impulseInputState.rightText"
      :bar-width="impulseInputState.barWidth"
      :glow="glow"
    >
      <template
        v-if="!isMobile()"
        #leftInfo
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
          :glow="glow"
          :class="glow ? '' : isLeft ? '' : 'disabled-info'"
        />
      </template>
      <template
        v-if="!isMobile()"
        #rightInfo
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
          :glow="glow"
          :class="glow ? '' : isRight ? '' : 'disabled-info'"
        />
      </template>
    </impulse-input-component>
  </div>
</template>

<script lang="ts">
import {
  ImpulseInputComponent,
  TutorialKeyboardKey,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { Sides } from '@/app/types'
import { defineComponent } from 'vue'
import { impulseInputState } from '@/stores'

export default defineComponent({
  name: 'ImpulseInput',
  components: { ImpulseInputComponent,
    TutorialKeyboardKey },
  mixins: [WindowAspect],
  props: {
    glow: {
      type: Boolean,
      default: false
    }
  },
  data() {

    return {
      impulseInputState: impulseInputState(),
    }

  },
  computed: {
    isLeft() {

      return impulseInputState().side === Sides.LEFT

    },
    isRight() {

      return impulseInputState().side === Sides.RIGHT

    }
  }
})

</script>

<style lang="less">
    .impulse-input {
        position: absolute;
        bottom: 20%;
        transform: translateX(-50%);
        left: 50%;
        width: 100%;
        display: flex;
        justify-content: center;

        .impulse-input-wrapper {
            justify-content: center;
        }

        .disabled-info {
            opacity: 0.5
        }
        .left-info {
            position: absolute;
            left: -135px;
            bottom: -20px;
        }
        .right-info {
            position: absolute;
            right: -135px;
            bottom: -20px;
        }

        .left {
            margin-right: 5%;
            width: 406px;
        }
        .right {
            margin-left: 5%;
            width: 406px;
        }
        .bar {
            margin: 0 auto;
        }
    }
</style>
