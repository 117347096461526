import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  settings,
  SettingsTypes,
  gameStats,
  CustomEvents,
  THREE,
  AudioManager,
  CameraStates,
  cameraManager,
  AppWSM2021DifficultyTypes,
  appWSM2021Config,
  modes,
  playersConfig,
  PlayersSecondResultTypes,
  requestManager,
  corePhasesManager,
  TimesTypes,
  fpsManager,
  CorePhases
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  translatesReplacements
} from './config'
import {
  TexturesNames,
  MaterialsNames,
  DisciplinePhases,
  type SpecialDataFromInit
} from './types'
import { hill } from './entities/hill/Hill'
import { player } from './entities/player'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { stateManager } from './StateManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { pathAssets } from '@/globals/globalvariables'
import { opponent } from './entities/Opponent/Opponent'
import { playersManager } from '@powerplay/core-minigames'
import * as Sentry from '@sentry/vue'
import {
  debugState,
  splitTimeState,
  trainingResultsState,
  trainingState,
  tutorialState
} from '@/stores'
import { loadingState } from '@powerplay/core-minigames-ui'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore!: MainCore

  // Aktualna pozicia kamery
  private actualCameraPosition = new THREE.Vector3()

  // Aktualna rotacia kamery
  private actualCameraQuaternion = new THREE.Quaternion()

  /** Pause prveho tutorialu */
  private pausedFirstTutorial = false

  /*
   * private mojArray: {id: number, position: THREE.Vector3}[] = [];
   * pocitadlo = 2;
   */

  /**
   * Konstruktor
   */
  public constructor() {

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    if (modes.isTutorial()) {

      // nastavime loading pre tutorial TODO zmen text na nazov discipliny
      loadingState().tutorial = {
        isEnabled: true,
        text: 'disciplineName5'
      }

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime zatial nic nastavovat, lebo berieme default hodnoty z konfigu
     */
    this.setPlayersConfig()

    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: materialsConfig[MaterialsNames.skier].meshesArray || [],
        materials: materialsConfig,
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackAction2: inputsManager.onKeyAction2,
            callbackAction3: inputsManager.onKeyAction3,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: batchingConfig,
        debugConfig,
        numberOfAttempts: gameConfig.numberOfAttempts,
        inputSchema: 'biathlon'
      },
      translatesReplacements,
      {
        textures: texturesConfig,
        models: modelsConfig,
        audio: audioConfig
      }
    )

    this.initialSetup()

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (stateManager.getFpsStarted()) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }
      stateManager.setFpsData(fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)
    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

  }

  /**
   * Inicializacny setup
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 5

    AudioManager.PATH_ASSETS = pathAssets
    disciplinePhasesManager.create()

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      tutorialState().settings = true

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.skierRaceBlackMan,
      TexturesNames.skierRaceBlackWoman,
      TexturesNames.skierRaceMulattoMan,
      TexturesNames.skierRaceMulattoWoman,
      TexturesNames.skierRaceWhiteMan,
      TexturesNames.skierRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.skierRacePrefix}${playerInfo.race}`)

    // pridame superov, ak su
    const opponentInfo = playersManager.getPlayerById(opponent.uuid)
    usedTextures.push(`${opponentInfo?.sex}/${TexturesNames.skierRacePrefix}${opponentInfo?.race}`)

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    game.shadowsManager.setShadowPlaneResolution(50, 50)
    game.shadowsManager.setShadowCameraFrustumPlaneDimensions(15, 15, 15, 15)

    opponent.setIsActive()
    opponent.setUuid()

    this.mainCore.init(
      undefined,
      undefined,
      undefined,
      undefined,
      opponent.isActive ? [opponent.uuid] : [],
      this.getIgnoredTexturesNames(),
      TexturesNames.skierRacePrefix
    )
    this.mainCore.setTweenSettingsForStates(cameraConfig.tweenSettingsForCameraStates)
    cameraManager.changeBaseRenderSettings(undefined, 300)
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)

    if (corePhasesManager.firstInstructions) {

      trainingResultsState().isDisabledPlayAgain = true

    }

  }

  /**
   * Nastavenie konfigu pre hracov
   */
  private setPlayersConfig(): void {

    playersConfig.secondResultType = PlayersSecondResultTypes.numberArray

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()
    loadingState().loadingProgress = gameStats.getLoadingProgress()

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    const specialData = data as SpecialDataFromInit
    splitTimeState().wr = specialData.worldRecord

    Sentry.setContext('minigame', { id: requestManager.MINIGAME_START_ID })

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    hill.create()
    player.create()
    opponent.create()

    // tiene
    game.shadowsManager.attachPlaneToObject(player.playerObject)

    this.setUpDebug()

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      hill,
      inputsManager,
      player,
      disciplinePhasesManager,
      cameraManager: cameraManager,
      setHUDVisibility: () => (debugState().isHudActive = true),
      pauseGame: () => {

        if (game.paused) game.resumeGame()
        else game.pauseGame()

      },
      scene: game.scene,
      game,
      THREE,
      opponent
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    stateManager.setBeforeGameStartPhase()

  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    disciplinePhasesManager.update()
    this.checkFpsRequest()
    player.updateBeforePhysics()
    opponent.updateBeforePhysics()

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    if (requestManager.isFirstTrainingTutorial() && !this.pausedFirstTutorial) {

      this.pausedFirstTutorial = true
      console.log(requestManager.TUTORIAL_ID)
      trainingState().firstTutorialMessage = true
      game.pauseGame()
      return

    }
    player.updateAfterPhysics()

    this.actualCameraPosition.copy(player.getPosition())
    if (
      disciplinePhasesManager.actualPhase > DisciplinePhases.start &&
            disciplinePhasesManager.actualPhase < DisciplinePhases.finish
    ) {

      this.actualCameraQuaternion.slerp(player.getQuaternion(), 0.08)

    } else {

      this.actualCameraQuaternion.copy(player.getQuaternion())

    }

    cameraManager.move(
      this.actualCameraPosition,
      this.actualCameraQuaternion,
      delta,
      [],
      cameraConfig.distanceFromGround,
      cameraManager.isThisCameraState(CameraStates.disciplineOutro),
      undefined,
      undefined
    )

    stateManager.setUpdateTimeState()

    if (modes.isTutorial()) tutorialFlow.update()

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    player.updateAnimations(delta)
    opponent.updateAnimations(delta)

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

}
