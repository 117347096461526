import { velocityConfig } from '../app/config'
import { Sides } from '../app/types'
import { defineStore } from 'pinia'

export interface ImpulseInputState {
  show: boolean,
  side: Sides,
  frames: {
    total: number,
    correct: number,
    incorrect: number
  },
  barDuration: number,
  leftText: string,
  rightText: string,
  successImpulsesCount: number,
  barWidth: number
}

const initialState = (): ImpulseInputState => ({
  show: false,
  side: Sides.LEFT,
  frames: {
    total: 0,
    correct: 0,
    incorrect: 0
  },
  barDuration: 0,
  leftText: '',
  rightText: '',
  successImpulsesCount: 0,
  barWidth: velocityConfig.impulseBar.defaultBarWidth
})

export const impulseInputState = defineStore('impulseInputState', {
  state: initialState,
})
