<template>
  <section
    :class="isMobile() ? 'mobile-overlay' : ''"
    class="tutorial"
  >
    <tutorial-tasks
      :tasks="tasks"
    />
    <tutorial-overlay
      v-if="
        tutorialState.anne.showAnne || tutorialState.tutorialMessage.showMessage
      "
      style="pointer-events: auto"
      @click.prevent=""
      @mousedown.prevent=""
      @mouseup.prevent=""
      @touchstart.prevent=""
      @touchend.prevent=""
    />
    <tutorial-anne
      v-if="tutorialState.anne.showAnne"
      :is-right="tutorialState.anne.isRight"
    />
    <tutorial-message-box
      v-if="tutorialState.tutorialMessage.showMessage"
      :key="tutorialCoreState.typeWriter"
      :type-write="tutorialCoreState.typeWriter"
      :class="offsetClass"
      :header-text="$t('anne')"
      :text="$t(tutorialState.tutorialMessage.message)"
      :color="tutorialState.tutorialMessage.color"
      :button-yellow-text="tutorialState.tutorialMessage.yellowText"
      :force-hide-white-text="tutorialState.tutorialMessage.yellowTextSpecial"
      @show-full-text="setTypewriteToInactive"
    />
    <tutorial-hand-settings
      v-if="tutorialState.mobile"
      style="pointer-events: auto"
      :img-url="'https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/'
        + 'speed-skating/ui/tutorial/MOBIL_SS.png'"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click-continue="continueTask"
    >
      <div class="mobiler">
        <div class="buttons">
          <div
            class="button-group"
            :class="{ reverse: !gameSettingsState.isLeft }"
          >
            <img
              width="150"
              height="150"
              :src="`${pathAssets}/ui/tutorial/button_ss_right.png`"
              alt="button2"
            >
          </div>
          <div
            class="button-group"
            :class="{ reverse: gameSettingsState.isLeft }"
          >
            <img
              width="150"
              height="150"
              :src="`${pathAssets}/ui/tutorial/button_ss_left.png`"
              alt="button2"
            >
          </div>
        </div>
      </div>
    </tutorial-hand-settings>
    <tutorial-buttons v-if="showTutorialButtons" />
    <tutorial-bars />
  </section>
</template>

<script lang="ts">
import {
  TutorialAnne,
  TutorialMessageBox,
  TutorialTasks,
  TutorialOverlay,
  TutorialHandSettings,
  WindowAspect,
  gameSettingsState
} from '@powerplay/core-minigames-ui'
import {
  settings,
  tutorialManager
} from '@powerplay/core-minigames'
import type { TutorialObjective } from '@powerplay/core-minigames'
import TutorialBars from '@/components/Tutorial/TutorialBars.vue'
import TutorialButtons from '@/components/Tutorial/TutorialButtons.vue'
import { TutorialObjectiveIds } from '@/app/types'
import { pathAssets } from '@/globals/globalvariables'
import { defineComponent } from 'vue'
import {
  impulseInputState,
  tutorialState
} from '@/stores'
import { tutorialCoreState } from '@powerplay/core-minigames-ui'

export default defineComponent({
  name: 'TutorialLogicComponent',
  components: {
    TutorialAnne,
    TutorialMessageBox,
    TutorialTasks,
    TutorialOverlay,
    TutorialHandSettings,
    TutorialBars,
    TutorialButtons
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      pathAssets,
      impulseInputState: impulseInputState(),
      tutorialState: tutorialState(),
      gameSettingsState: gameSettingsState(),
      tutorialCoreState: tutorialCoreState(),
    }

  },
  computed: {
    basicStyle() {

      return {
        'align-items': 'flex-end',
        width: '290px',
        height: '200px',
        padding: '20px'
      }

    },
    offsetClass() {

      return tutorialState().tutorialMessage.offset ?
        (this.isMobile() ? 'offsetBotMobile' : 'offsetBot') :
        ''

    },
    showTutorialButtons() {

      return this.isMobile() &&
        (tutorialState().buttons.showMovementButtons || tutorialState().buttons.showButtonStart)


    }
  },
  watch: {
    tutorialState: {
      immediate: true,
      deep: true,
      handler() {

        this.updateTasks()

      }
    },
    impulseInputState: {
      handler() {

        this.updateTasks()

      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    setTypewriteToInactive() {

      tutorialCoreState().typeWriter = false
      tutorialManager.setTypeWriting(false)

    },
    continueTask() {

      this.setThings()
      settings.saveValues()
      window.dispatchEvent(new Event('mousedown'))

    },
    setThings(): void {

      settings.update('quality', gameSettingsState().graphicsSettings)
      settings.update('sounds', gameSettingsState().volume ? 1 : 0)
      settings.update('qualityAuto', gameSettingsState().graphicsAuto ? 1 : 0)
      settings.update('isLeft', gameSettingsState().isLeft ? 1 : 0)

    },
    updateTasks() {

      this.tasks = tutorialState().objectives.map((objective: TutorialObjective) => {

        let color = 'blue'
        if (objective.passed) color = 'green'
        if (objective.failed) color = 'red'
        return {
          color,
          text: `${this.$t(objective.name)} ${
            objective.name === (TutorialObjectiveIds.impulses as string) ?
              `${impulseInputState().successImpulsesCount}/10` :
              ''
          }`
        }

      })

    }
  }
})

</script>

<style lang="less">
.tutorial {
  &.mobile-overlay {
    .tutorial-anne, .tutorial-mobil, .tutorial-hand-bottom {
        position: fixed;
    }
  }

  .offsetBot {
    bottom: 50%;
  }
  .offsetBotMobile {
    bottom: 60%;
  }

  .tutorial-tasks {
    position: absolute;
    left: 1%;
    top: 17%;
  }
}
.mobiler {
    height: 100%;

    .buttons {
        position: relative;
        width: 100%;
        height: 100%;

        .button-group {
            position: absolute;
            bottom: 130px;
            left: 130px;
            right: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.reverse {
                flex-direction: row-reverse;
            }
        }
    }
}
</style>
