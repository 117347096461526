import { defineStore } from 'pinia'

export interface TimeState {
  penaltySeconds: number,
  timeWithPenalty: string,
  time: string
}

const initialState = (): TimeState => ({
  penaltySeconds: 0,
  timeWithPenalty: '0.0',
  time: '0.0'
})

export const timeState = defineStore('timeState', {
  state: initialState,
})
