import type { RunUpPhase } from '@/app/phases/RunUpPhase/RunUpPhase'
import {
  THREE,
  AnimationsManager,
  game,
  errorManager,
  minigameConfig,
  CameraStates,
  playersManager,
  cameraManager,
  fpsManager
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig,
  animationsConfig
} from '../../config'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  ModelsNames,
  DisciplinePhases,
  type IntersectionInfo,
  CurveType,
  PlayerTypes
} from '../../types'
import { playerAnimationManager } from '.'
import { HillLinesCreator } from '../hill/HillLinesCreator'
import { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import { TriggersManager } from '../triggers/TriggersManager'
import type { RunningPhase } from '@/app/phases/RunningPhase/RunningPhase'
import { opponent } from '../Opponent/Opponent'
import { speedmeterState } from '@/stores'

/**
 * Trieda pre hraca
 */
export class Player {

  /** 3D objekt lyziara - cela scena */
  public playerObject = new THREE.Object3D()

  /** Manager pre animacie */
  public animationsManager!: AnimationsManager

  /** UUID spera */
  public uuid = ''

  /** 3D objekt, v ktorom sa nachadza kamera a ktoremu sa meni pozicia lerpom postupne */
  private goalObject = new THREE.Object3D()

  /** Pomocny vektor na lerp na normalu svahu */
  private normalLerp = new THREE.Vector3()

  /** ci prebieha endphase */
  private endPhase = false

  /** hrac je skrceny */
  public isCrouching = false

  /** hrac je sprinting */
  public isSprinting = false

  /** hrac sa rozbieha */
  public isSkating = false

  /** hrac je starting */
  public isStarting = false

  /** ci bol failed start */
  public isFailed = false

  /** hrac je na konci */
  public isEnd = false

  /** Ci bol prepad pod trat alebo nie */
  public outOfBounds = false

  /** manager rychlosti */
  public speedManager = new SpeedManager()

  /** hracov final time */
  public finalTime = minigameConfig.dnfValue

  /** Bezecka faza */
  private runUpPhase!: RunUpPhase

  /** Upravuje vysku hraca nad tratou */
  private readonly SKIER_POSITION_Y_ADJUST = -1.2

  /** Ci je aktivne aktualizovanie pohybovych animacii */
  public activeUpdatingMovementAnimations = false

  /** callback pri kolizii */
  private collisionEndCallback!: () => unknown

  /** Vzdialenost od priesecnika povrchu */
  private intersectionDistance = 0

  /** Normala priesecnika s povrchom */
  private intersectionNormal = new THREE.Vector3()

  /** Bod priesecnika */
  private intersectionPoint = new THREE.Vector3()

  /** triggers manager */
  public triggersManager = new TriggersManager(PlayerTypes.player)

  /** Posledna pozicia hraca */
  public lastPlayerPosition = new THREE.Vector3()

  /** ci sme po strelbe */
  public isAfterShooting = false

  /** dolezity manager na pohyb */
  public hillLinesManager!: HillLinesCreator

  /** Instalerp na instantny lerp */
  private instalerp = false

  /**
   * Vratenie infa o priesecniku hraca s hillom
   * @returns Info o priesecniku
   */
  public getIntersectionInfo(): IntersectionInfo {

    return {
      normal: this.intersectionNormal,
      distance: this.intersectionDistance,
      point: this.intersectionPoint
    }

  }

  /**
   * Vytvorenie lyziara
   * @param position - Startovacia pozicia lyziara
   */
  public create(position = gameConfig.startPosition): void {

    console.log('vytvaram hraca...')

    this.uuid = playersManager.getPlayer().uuid

    this.speedManager.setTopSpeedFromAttribute(playersManager.getPlayer().attribute.total)
    this.speedManager.setSpeedAutoIncreaseRunUpFromAttribute(playersManager.getPlayer().attribute.total)

    const meshSkierName = modelsConfig[ModelsNames.skier]?.mainMeshNames?.[0]
    if (!meshSkierName) {

      throw new Error(errorManager.showBox('Mesh name for skier was not defined'))

    }

    console.warn(game.getObject3D(meshSkierName))
    this.playerObject = game.getObject3D(meshSkierName)

    game.scene.add(this.playerObject)

    // animacie
    this.animationsManager = new AnimationsManager(
      this.playerObject,
      animationsConfig,
      game.animations.get(ModelsNames.skier),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    // TODO: WHAT THE FUCK
    this.goalObject.position.set(position.x, position.y, position.z + 2)

    // threeJS Section
    this.playerObject.position.set(
      position.x,
      position.y + this.SKIER_POSITION_Y_ADJUST,
      position.z
    )
    this.playerObject.name = 'Player'

    this.runUpPhase = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.runUp) as RunUpPhase

    this.hillLinesManager = new HillLinesCreator('Raceline_Player', 'Raceline_Player_Finish')
    this.hillLinesManager.setFinishCallback(() => {

      const runningPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
      runningPhase.finishPhase()

    })
    this.triggersManager.setStartCallback(() => {

      const runningPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
      runningPhase.impulseInputManager.setIsCurve(true)
      playerAnimationManager.toggleTurn(true)
      this.triggersManager.setNextCurveTriggerType(CurveType.end)

    })
    this.triggersManager.setEndCallback(() => {

      const runningPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
      runningPhase.impulseInputManager.setIsCurve(false)
      playerAnimationManager.toggleTurn(false)
      this.triggersManager.setNextCurveTriggerType(CurveType.start)
      this.triggersManager.incrementIndex()

    })

    console.log('hrac vytvoreny...')

  }

  /**
   * Zmena vektora kamery smerom hore na spravnu poziciu
   */
  private changeCameraVectorUp(): void {

    if (cameraManager.isThisCameraState(CameraStates.discipline)) {

      cameraManager.getMainCamera().up.set(
        this.normalLerp.x,
        this.normalLerp.y,
        this.normalLerp.z
      )

    }

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.playerObject.quaternion

  }

  /**
   * Vratenie pozicie lyziara
   * @returns Pozicia lyziara
   */
  public getPosition(): THREE.Vector3 {

    return this.playerObject.position

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   */
  public updateBeforePhysics(): void {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.end) return

    const point = this.hillLinesManager.update(
      this.speedManager,
      this.triggersManager,
      opponent.triggersManager.splitTimeManager.opponentPassed
    )
    this.updatePlayerPosition(point)

    speedmeterState().speed = Number(this.speedManager.getActualSpeed().toFixed(2))

  }

  /**
   * Aktualizovanie pozicie lyziara
   */
  private updatePlayerPosition(positionObj: THREE.Object3D): void {

    this.playerObject.position.copy(positionObj.position)

    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart]
      .includes(disciplinePhasesManager.actualPhase) || this.instalerp

    // hned zresetujeme
    this.instalerp = false

    let t = 0.75
    if (instalerp) t = 1

    this.playerObject.quaternion.slerp(positionObj.quaternion, t)

    /*
     * this.playerObjectSecond.position.copy(this.playerObject.position)
     * this.playerObjectSecond.quaternion.copy(this.playerObject.quaternion)
     */

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   * @param hillMesh - Mesh kopca
   */
  public updateAfterPhysics(): void {

    playerAnimationManager.update()

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    this.animationsManager.update(delta)

  }

  /**
   * Setter
   * @param phasesManager - phasesManager
   */
  public setCollisionEndCallback(collisionEndCallback: () => unknown): Player {

    this.collisionEndCallback = collisionEndCallback
    return this

  }

  /**
   * Spustenie animacie odrazenia na zaciatku
   */
  public launchStartAnimation = (failed: boolean): void => {

    this.isStarting = true
    this.isFailed = failed

  }

  /**
   * Konecna akcia pre hraca
   */
  public finishAction(): void {

    // najskor musime ukoncit moznost pohybovych animacii
    this.activeUpdatingMovementAnimations = false
    this.endPhase = true

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

  }

  /**
   * changes config of camera
   * @param idealOffset - ideal shift of camera from player
   * @param idealLookAt - ideal place for camera to look at
   * @param coefSize - how fast should camera move (0-1)
   * @param changeLerp - how fast changes should be applied (0-1)
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number,
    isStaticMovement?: boolean
  ): void {

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp,
      isStaticMovement
    )

  }

  /**
   * changes camera render settings
   * @param near - how close to camera stuff should be rendered
   * @param far - how far from camera stuff should be rendered
   * @param fov - field of view of camera
   */
  public changeCameraRenderSettings(near?: number, far?: number, fov?: number): void {

    cameraManager.changeRenderSettings(near, far, fov)

  }

  /**
   * nastavime camera settings podla game configu
   *
   * @param lerpSize - volitelny iny lerp ako v game configu
   */
  public setGameCameraSettings(lerpSize = gameConfig.cameraConfig.changeLerp): void {

    this.changeCameraSettings(
      gameConfig.cameraConfig.idealOffset,
      gameConfig.cameraConfig.idealLookAt,
      gameConfig.cameraConfig.coefSize,
      lerpSize,
      gameConfig.cameraConfig.isStaticMovement
    )

  }

  /**
   * reset hraca
   */
  public reset(): void {

    this.isStarting = false
    this.isSkating = false
    this.isFailed = false
    this.isEnd = false
    this.endPhase = false
    this.isCrouching = false
    this.isSprinting = false
    this.activeUpdatingMovementAnimations = false
    this.animationsManager.resetAll()
    this.speedManager.reset()
    this.triggersManager.reset()
    /*
     * TODO TEMP - toto takto nemoze byt, bude treba fixnut v buducnosti
     * this.hillLinesManager = new HillLinesCreator()
     */
    playerAnimationManager.reset()

  }

}

export const player = new Player()
