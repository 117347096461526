import { defineStore } from 'pinia'

export interface UiState {
  showTimeKeeper: boolean,
  showFinishTopBox: boolean,
  showTrainingLayout: boolean,
  isTraining: boolean,
  showBlack: boolean
}

const initialState = (): UiState => ({
  showTimeKeeper: false,
  showFinishTopBox: false,
  showTrainingLayout: false,
  isTraining: false,
  showBlack: false
})

export const uiState = defineStore('uiState', {
  state: initialState,
})
