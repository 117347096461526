import { tutorialConfig } from '@/app/config'
import type { RunUpPhase } from '@/app/phases/RunUpPhase/RunUpPhase'
import type { StartPhaseManager } from '@/app/phases/StartPhase/StartPhase'
import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  MobileDetector,
  TutorialMessageColors,
  requestManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  DisciplinePhases,
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'
import {
  blurState,
  impulseInputState,
  tutorialState
} from '@/stores'
import { tutorialCoreState } from '@powerplay/core-minigames-ui'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  /** aktualny event */
  private activeEventType = TutorialEventType.awaitingEvent

  /** ci uz skoncil prvu zakrutu */
  private firstCurveDone = false

  /** ci uz skoncil druhu zakrutu */
  private secondCurveDone = false

  /** pocitadlo excelent a perfect impulzov */
  public correctImpulses = 0

  /** pocitadlo failed startov */
  public failedStartCount = 0

  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.start as string,
        passed: false,
        failed: false,
        name: 'tutorialTask5-1'
      },
      {
        id: TutorialObjectiveIds.acceleration as string,
        passed: false,
        failed: false,
        name: 'tutorialTask5-2'
      },
      {
        id: TutorialObjectiveIds.impulses as string,
        passed: false,
        failed: false,
        name: 'tutorialTask5-3'
      },
      {
        id: TutorialObjectiveIds.finish as string,
        passed: false,
        failed: false,
        name: 'tutorialTask5-4'
      }
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    const tutorialSections = [
      {
        name: SectionNames.handCheck,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          blurState().isActive = false
          this.setObjectivesInit()

        }
      },
      {
        name: SectionNames.startSection,
        id: 1,
        type: TutorialSectionType.storyInput
      },
      {
        name: SectionNames.startSectionSecond,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          this.resetTypeWrite()
          tutorialState().buttons = {
            showButtonStart: true,
            showMovementButtons: false
          }

        },
        sectionLogicFinish: () => {

          tutorialState().buttons = {
            showButtonStart: false,
            showMovementButtons: false
          }
          this.resetTypeWrite()
          const startPhaseManager = disciplinePhasesManager
            .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
          startPhaseManager.launchSystem()

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 3,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.afterRunUpSection,
        id: 4,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startRunningSection,
        id: 5,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startOfSecondCurveSection,
        id: 6,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.finishSection,
        id: 7,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.endSection,
        id: 8,
        type: TutorialSectionType.gameEvent
      }
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) return
    tutorialManager.setActualSectionId(1)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * Kontrola inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType

    if (eventType === TutorialEventType.failedStart) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText5-3')
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.goodStart) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText5-4', undefined, undefined, true)
      tutorialUIChange.setAnne(MobileDetector.isMobile() ? false : true, true)

      tutorialState().$patch({
        buttons: {
          showButtonStart: false,
          showMovementButtons: true
        },
        showBarStart: true
      })

    }
    if (eventType === TutorialEventType.afterRunUp) {

      const runUpPhaseManager = disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.runUp) as RunUpPhase
      if (runUpPhaseManager.runUpQuality >= tutorialConfig.runUpQualitySuccess) {

        tutorialObjectives.passObjective(TutorialObjectiveIds.acceleration)
        this.activeEventType = TutorialEventType.awaitingEvent
        tutorialManager.nextSection()
        return

      }

      tutorialObjectives.failObjective(TutorialObjectiveIds.acceleration)
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText5-5', undefined, undefined, true)
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.startRunning) {

      tutorialState().$patch({
        showImpulseBar: true,
        buttons: {
          showButtonStart: false,
          showMovementButtons: true
        }
      })

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText5-6', undefined, undefined, true)
      tutorialUIChange.setAnne(false)

    }
    if (eventType === TutorialEventType.startOfSecondCurve) {

      if (!this.firstCurveDone) {

        this.firstCurveDone = true
        return

      }
      if (this.secondCurveDone) {

        this.activeEventType = TutorialEventType.awaitingEvent
        tutorialManager.nextSection()

      }

      this.secondCurveDone = true
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText5-7')
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.finish) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.finish)
      this.resetTypeWrite()
      game.pauseGame()
      let text = 'tutorialText5-8'
      let color = TutorialMessageColors.green
      if (
        !tutorialObjectives.checkIfObjectivePassed(tutorialObjectives.getObjectiveById(TutorialObjectiveIds.impulses))
      ) {

        tutorialObjectives.failObjective(TutorialObjectiveIds.impulses)
        text = 'tutorialText5-9'
        color = TutorialMessageColors.red

      }
      tutorialUIChange.setMessage(true, text, color)
      tutorialUIChange.setAnne(true)

    }

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    tutorialCoreState().typeWriter = true

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()

    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (
      [
        TutorialEventType.goodStart,
        TutorialEventType.afterRunUp,
        TutorialEventType.startRunning,
        TutorialEventType.startOfSecondCurve
      ].includes(this.activeEventType)
    ) {

      tutorialState().$patch({
        buttons: {
          showButtonStart: false,
          showMovementButtons: false
        },
        showImpulseBar: false,
        showBarStart: false
      })

      tutorialManager.blockInputsManually()
      tutorialManager.nextSection()

    }
    if (this.activeEventType === TutorialEventType.finish) {

      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

    }

    this.activeEventType = TutorialEventType.awaitingEvent

  }

  /**
   * kontrola poctu spravnych impolzov
   * @returns boolean
   *
   */
  public isEnoughtCorrectImpulses(): boolean {

    return this.correctImpulses >= tutorialConfig.impulsesCorrectSuccess

  }

  /**
   * Pripocitanie pocitadla spravnych impulzov
   */
  public incrementCorrectImpulse(): void {

    if (!modes.isTutorial() || this.isEnoughtCorrectImpulses()) {

      return

    }
    this.correctImpulses += 1
    impulseInputState().successImpulsesCount = this.correctImpulses
    if (this.correctImpulses >= 10) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.impulses)

    }

  }

}

export const tutorialFlow = new TutorialFlow()
