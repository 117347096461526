<template>
  <section class="positioner">
    <section
      v-if="inputsState.isVisible && impulseInputState.show"
    >
      <movement-sides
        :disabled="inputsState.disabled"
        ignore-safe-zones
        @position="changeMovement"
        @end-contact="endContact"
      />
    </section>
    <section
      v-if="inputsState.isVisible && gamePhaseState.showUi && !runUpBarState.showBar"
    >
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between"
      >
        <section v-show="!actionButtonState.showJoystick" />
      </section>
      <section
        v-else
        class="flex justify-between"
      />
    </section>
    <section
      v-if="runUpBarState.showBar || startPhaseState.startable"
      class="action-sides-container"
    >
      <div
        class="left-side"
        :style="{transform: transformCoef}"
      >
        <mobile-button
          class="left-side-button"
          type="speed-up-skating-l"
          :is-scaled="false"
          @mousedown="changeMovement(-1)"
          @mouseup="endContactZeroed()"
          @touchstart="changeMovement(-1)"
          @touchend="endContactZeroed()"
        />
      </div>
      <div
        class="right-side"
        :style="{transform: transformCoef}"
      >
        <mobile-button
          class="right-side-button"
          type="speed-up-skating-r"
          :is-scaled="false"
          @mousedown="changeMovement(1)"
          @mouseup="endContactZeroed()"
          @touchstart="changeMovement(1)"
          @touchend="endContactZeroed()"
        />
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import {
  MovementSides,
  MobileButton,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import {
  actionButtonState,
  gamePhaseState,
  impulseInputState,
  inputsState,
  movementState,
  runUpBarState,
  startPhaseState
} from '@/stores'
import { gameSettingsState } from '@powerplay/core-minigames-ui'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    MovementSides,
    MobileButton
  },
  mixins: [WindowAspect],
  data() {

    return {
      actionButtonState: actionButtonState(),
      gamePhaseState: gamePhaseState(),
      impulseInputState: impulseInputState(),
      inputsState: inputsState(),
      runUpBarState: runUpBarState(),
      startPhaseState: startPhaseState(),
      gameSettingsState: gameSettingsState(),
    }

  },
  computed: {
    transformCoef() {

      return `scale(${ this.scaleCoef })`

    }
  },
  methods: {
    endContact(positionX: number) {

      let newPosX = 0
      if (movementState().positionX == 0) {

        newPosX = positionX

      }
      movementState().$patch({
        positionX: newPosX,
        isPressed: false
      })

    },
    endContactZeroed() {

      movementState().$patch({
        positionX: 0,
        isPressed: false
      })

    },
    changeMovement(positionX: number) {

      movementState().$patch({
        positionX: positionX,
        isPressed: true
      })

    }
  }
})

</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.buttons-position {
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: 200px;
}

.action-sides-container {
    height: 580px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;

    &.disabled {
        opacity: 0.7;
    }

    .power-mobile-button{
        .button-element{
            .image{
                height: 100px;
                width: 100px;
                background-size: cover;
                left: 50%;
                margin-left: -50px;
            }
        }
    }

    .left-side{
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        transform-origin: bottom left;

        .left-side-button {
            .button-element {
                .image {
                    margin-left: -30px;
                }
            }
        }
    }

    .right-side{
        display: flex;
        position: fixed;
        right: 0;
        bottom: 0;
        transform-origin: bottom right;

        .right-side-button {
            .button-element {
                .image {
                    margin-left: 20px;
                }
            }
        }
    }

}
.bottom-right {
  transform-origin: bottom right;
}
.bottom-left {
  transform-origin: bottom left;
}
</style>
