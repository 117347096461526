import { Sides } from '../app/types'
import { defineStore } from 'pinia'

export interface RunUpBarState {
  showBar: boolean,
  speedBarValue: number,
  maxSpeed: number,
  nextImpulse: Sides | undefined
}

const initialState = (): RunUpBarState => ({
  showBar: false,
  speedBarValue: 0,
  maxSpeed: 100,
  nextImpulse: undefined
})

export const runUpBarState = defineStore('runUpBarState', {
  state: initialState,
})
