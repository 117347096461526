<template>
  <div
    v-if="tableState.showTable"
    class="info-component"
    :class="[isMobile() ? 'mobile-info-component' : '']"
  >
    <info-box />
  </div>
</template>

<script lang="ts">
import {
  InfoBox,
  WindowAspect
} from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'
import { tableState } from '@/stores'

export default defineComponent({
  name: 'InfoComponent',
  components: {
    InfoBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      tableState: tableState(),
    }

  },
})

</script>

<style lang="less" scoped>
.info-component {
   position: absolute;
   bottom: 5%;
   width: 100%;
}
</style>
