import {
  gameConfig,
  runningPhaseConfig,
  trainingConfig,
  velocityConfig
} from '@/app/config'
import { inputsManager } from '@/app/InputsManager'
import {
  ImpulseQualityTexts,
  Sides,
  Tasks
} from '@/app/types'
import { player } from '.'
import {
  gsap,
  modes
} from '@powerplay/core-minigames'
import { trainingTasks } from '@/app/modes/training'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import {
  impulseInputState,
  movementState
} from '@/stores'

/**
 * Impulse Input manager
 * Trieda, ktora sa stara o ovladanie pri korculovani
 */
export class ImpulseInputManager {

  /** aktualne aktivna strana impulzu */
  private actualImpulse: Sides = Sides.LEFT

  /** pocitadlo framov */
  private frameCounter = -1

  /** pocet framov v spravnom kliku */
  private pressedFrameCounterCorrect = 0

  /** pocet framov v nespravnom kliku */
  private pressedFrameCounterIncorrect = 0

  /** trvanie jednej strany impulzu */
  private barDuration: number

  /** ci sa pocas aktualneho impulzu zmenila dlzka strany */
  private durationSwitched = false

  /** ci sme v zakrute */
  private isCurve = false

  /** ako dlho je klik zablokovany */
  private clickBlockedFrames = 0

  /** ci je klik zablokovany */
  private isBarBlocked = false

  /** impulzy pre vypocitanie priemeru celkovo */
  private impulses: number[] = []

  /** impulzy pre vypocitanie priemeru v rovinkach */
  private impulsesStraight: number[] = []

  /** impulzy pre vypocitanie priemeru v zakrutach */
  private impulsesCurve: number[] = []

  /** sirka barov */
  private barWidth: number

  /** sirka baru pri zakrute */
  private barWidthCurve: number

  /** hodnota kroku pri animacii */
  private barWidthAnimationStep: number

  /** Pocet dokonalych zaberov */
  public perfectImpulses = 0

  /** Pocet dokonalych zaberov v zakrutach */
  public perfectImpulsesCurves = 0

  /**
   * Konstruktor
   */
  public constructor() {

    const {
      barDurationStraight, defaultBarWidth, barDurationCurve, animationTime
    } = velocityConfig.impulseBar

    this.barDuration = barDurationStraight
    this.barWidth = defaultBarWidth
    this.barWidthCurve = defaultBarWidth * barDurationCurve / barDurationStraight
    this.barWidthAnimationStep = (this.barWidth - this.barWidthCurve) / animationTime

  }

  /**
   * Update metoda ktoru mame pouzit externe
   */
  public update(): void {

    this.animateBarWidth()
    this.updateStore()

    if (this.isBarBlocked) {

      this.clickBlockedFrames++
      if (this.clickBlockedFrames % runningPhaseConfig.changeFreezeDuration) return
      this.isBarBlocked = false

    }

    this.frameCounter += 1

    if (this.frameCounter % velocityConfig.speedAutoDecreaseFrames === 0 &&
             this.frameCounter !== 0) {

      player.speedManager.autodecreaseImpulse()

    }

    if (this.frameCounter % this.barDuration === 0 && this.frameCounter !== 0) {

      this.frameCounter = -1
      this.changeActualImpulse()
      // po zmene impulzu moze byt pohyb zablokovany
      if (this.isBarBlocked) return

    }

    this.autoMove()

    if (gameConfig.autoMove.isEnabled) return

    const dirLeft = inputsManager.moveDirectionLeft || movementState().positionX < 0

    const dirRight = inputsManager.moveDirectionRight || movementState().positionX > 0

    const isMobileBoth = !dirLeft && !dirRight && movementState().isPressed

    if ((dirLeft && dirRight) || isMobileBoth) {

      this.pressedFrameCounterIncorrect += 1
      return

    }

    if (this.actualImpulse === Sides.LEFT) {

      if (dirLeft) this.pressedFrameCounterCorrect += 1
      if (dirRight) {

        this.pressedFrameCounterIncorrect += 1

      }

    }
    if (this.actualImpulse === Sides.RIGHT) {

      if (dirRight) this.pressedFrameCounterCorrect += 1
      if (dirLeft) {

        this.pressedFrameCounterIncorrect += 1

      }

    }

  }

  /**
   * auto move logika
   */
  private autoMove(): void {

    if (!gameConfig.autoMove.isEnabled) return

    this.pressedFrameCounterCorrect += 1

  }

  /**
   * Prepinanie barDuration podla toho, ci sme alebo nie sme v zakrute
   * @param value - hodnota isCurve
   */
  public setIsCurve(value: boolean): void {

    this.isCurve = value
    this.durationSwitched = true

  }

  /** zmena na dalsie slacenie impulzu */
  public changeActualImpulse(): void {

    const impulse = this.pressedFrameCounterCorrect / this.barDuration

    if (this.isCurve) {

      this.impulsesCurve.push(impulse)

    } else {

      this.impulsesStraight.push(impulse)

    }
    this.impulses.push(impulse)

    player.speedManager.resolveImpulsePower(this.barDuration, this.pressedFrameCounterCorrect)

    let text = ImpulseQualityTexts.poor
    const quality = 100 / this.barDuration * this.pressedFrameCounterCorrect

    if (quality >= velocityConfig.impulseBar.qualityPercent.perfect) {

      text = ImpulseQualityTexts.perfect
      tutorialFlow.incrementCorrectImpulse()
      if (this.isCurve) this.perfectImpulsesCurves++
      this.perfectImpulses++

    } else if (quality >= velocityConfig.impulseBar.qualityPercent.excellent) {

      text = ImpulseQualityTexts.excellent
      tutorialFlow.incrementCorrectImpulse()

    } else if (quality >= velocityConfig.impulseBar.qualityPercent.good) {

      text = ImpulseQualityTexts.good

    }

    if (modes.isTrainingMode()) trainingTasks.countTaskValue(Tasks.skateQuality, trainingConfig.impulsesQualities[text])

    this.pressedFrameCounterCorrect = 0
    this.pressedFrameCounterIncorrect = 0
    this.clickBlockedFrames = 0
    this.isBarBlocked = true

    if (this.actualImpulse === Sides.LEFT) {

      impulseInputState().leftText = text
      gsap.to({}, {
        onComplete: () => {

          impulseInputState().leftText = ''

        },
        duration: 1
      })
      this.actualImpulse = Sides.RIGHT

    } else {

      impulseInputState().rightText = text
      gsap.to({}, {
        onComplete: () => {

          impulseInputState().rightText = ''

        },
        duration: 1
      })
      this.actualImpulse = Sides.LEFT

    }

    if (!this.durationSwitched) return

    this.durationSwitched = false
    if (this.isCurve) {

      this.barDuration = velocityConfig.impulseBar.barDurationCurve

    } else {

      this.barDuration = velocityConfig.impulseBar.barDurationStraight

    }

  }

  /**
   * Vrati priemerny impuls
   * @returns priemer
   */
  public getAverageQuality(): number {

    if (this.impulses.length === 0) return 0
    return this.impulses.reduce((a, b) => a + b, 0) / this.impulses.length

  }

  /**
   * Vrati priemerny impuls na rovinkach
   * @returns priemer
   */
  public getStraightAvgQuality(): number {

    if (this.impulsesStraight.length === 0) return 0
    return this.impulsesStraight.reduce((a, b) => a + b, 0) / this.impulsesStraight.length

  }

  /**
   * Vrati priemerny impuls na rovinkach
   * @returns priemer
   */
  public getCurveAvgQuality(): number {

    if (this.impulsesCurve.length === 0) return 0
    return this.impulsesCurve.reduce((a, b) => a + b, 0) / this.impulsesCurve.length

  }

  /**
   * ulozenie novych stavov do vuex
   */
  public updateStore(): void {

    impulseInputState().$patch({
      show: true,
      side: this.actualImpulse,
      frames: {
        total: this.frameCounter,
        correct: this.pressedFrameCounterCorrect,
        incorrect: this.pressedFrameCounterIncorrect
      },
      barDuration: this.barDuration,
      barWidth: this.barWidth
    })

  }

  /**
   * zmensovanie a zvacsovanie sirky impulse input baru
   */
  public animateBarWidth(): void {

    if (this.isCurve) {

      this.barWidth -= this.barWidthAnimationStep
      if (this.barWidth < this.barWidthCurve) this.barWidth = this.barWidthCurve

    } else {

      const { defaultBarWidth } = velocityConfig.impulseBar

      this.barWidth += this.barWidthAnimationStep
      if (this.barWidth > defaultBarWidth) this.barWidth = defaultBarWidth

    }

  }

}
