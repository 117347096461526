import { defineStore } from 'pinia'
export interface DebugState {
  isHudActive: boolean
}

const initialState = (): DebugState => ({
  isHudActive: false
})

export const debugState = defineStore('debugState', {
  state: initialState,
})
