<template>
  <div class="speed-bar-container">
    <div
      class="positioner"
    >
      <speed-control-bar
        class="bottom-right"
        :fill-size="runUpBarState.speedBarValue"
        :max-fill="runUpBarState.maxSpeed"
        :reversed-mode="true"
        show-speed-sign
        :show-mark="false"
        only-green
        :glow="glow"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  SpeedControlBar,
  WindowAspect
} from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'
import { runUpBarState } from '@/stores'

export default defineComponent({
  name: 'SpeedBar',
  components: {
    SpeedControlBar
  },
  mixins: [WindowAspect],
  props: {
    isLeft: {
      type: Boolean,
      default: false
    },
    glow: {
      type: Boolean,
      default: false
    }
  },
  data() {

    return {
      runUpBarState: runUpBarState(),
    }

  },

})

</script>

<style scoped lang="less">
.speed-bar-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 580px;

    .positioner {
        display: none;
        position: absolute;
        bottom: 20%;
        right: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;
    }
}
.bottom-right {
    transform-origin: bottom right;
}
</style>
