<template>
  <div class="info-key-positioner">
    <tutorial-keyboard-keys
      v-if="showSideArrows"
      type="arrow-keys"
      :text="$t('webInfoAcceleration')"
      is-scaled
      class="keyboard-side-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
        />
      </section>
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showVerticalArrows"
      type="arrow-keys"
      :text="$t('webInfoTempo')"
      is-scaled
      class="keyboard-vertical-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="top"
          letter="w"
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="down"
          letter="s"
        />
      </section>
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showSpace"
      type="space"
      :text="$t('webInfoStart')"
      class="keyboard-space"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space"
      />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-len */
import {
  TutorialKeyboardKeys,
  TutorialKeyboardKey
} from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'
import {
  runUpBarState,
  startPhaseState
} from '@/stores'

export default defineComponent({
  name: 'TutorialKeys',
  components: {
    TutorialKeyboardKeys,
    TutorialKeyboardKey
  },
  computed: {
    showSpace() {

      return false

    },
    showSideArrows() {

      return runUpBarState().showBar || startPhaseState().startable

    },
    showVerticalArrows() {

      return false

    }
  }
})

</script>

<style lang="less">
    .info-key-positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }
        .keyboard-vertical-keys {
            transform-origin: 100% 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                width: 100px;
            }
            .tutorial-keyboard-header {
                padding: 0 20px;
                width: 200px;
                margin-bottom: 40px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }

        .mouse-move {
            background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/core-ui/tutorial/ICO-MOUSE-MOVE.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 126px;
            height: 132px;
        }
        .mouse-click {
            background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/core-ui/tutorial/ICO-MOUSE-CLICK.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 20px 22px;
            width: 70px;
            height: 90px;
        }
    }
</style>
