import { defineStore } from 'pinia'

export interface GamePhaseState {
  showBar: boolean,
  showUi: boolean,
}

const initialState = (): GamePhaseState => ({
  showBar: false,
  showUi: true,
})

export const gamePhaseState = defineStore('gamePhaseState', {
  state: initialState,
})
