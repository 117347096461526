import type { DisplayMessage } from '@/app/types'
import {
  actionButtonState,
  inputsState,
  movementState,
  startMessageState,
  uiState
} from '@/stores'
import { modes } from '@powerplay/core-minigames'

export class StartPhaseStateManager {

  public disableInputs(): void {

    inputsState().disabled = true
    actionButtonState().disabled = false

  }

  public showInputs(): void {

    inputsState().$patch({
      isVisible: true,
      disabled: false
    })

  }

  public postIntroUiState(): void {

    uiState().$patch({
      showTimeKeeper: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    movementState().onlyHorizontal = true
    inputsState().disabled = false

  }

  public finishPhaseUiState(): void {

    uiState().$patch({
      showTimeKeeper: true,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

  }

  public enableStartInputs(): void {

    inputsState().$patch({
      disabled: false,
      isVisible: true
    })
    actionButtonState().$patch({
      disabled: false,
      isStart: true
    })

  }

  public showStartMessageInUI(message: DisplayMessage): void {

    startMessageState().$patch({
      showMessage: true,
      messageText: message.message,
      messageColor: message.color
    })

  }

  public disableStartMessageInUI(message: DisplayMessage): void {

    startMessageState().$patch({
      showMessage: false,
      messageText: message.message,
      messageColor: message.color
    })

  }

}

export const startPhaseStateManager = new StartPhaseStateManager()
