import {
  trainingManager,
  modes
} from '@powerplay/core-minigames'
import { Tasks } from '../../types'
import { trainingState } from '@/stores'

/**
 * Trieda pre treningove ulohy
 */
export class TrainingTasks {

  /** Mnozstvo taskov */
  private NUMBER_OF_TASKS = 3

  /** Pomocka na trening */
  private allQualities: number[] = []

  /** Kvalita jazdy */
  private skateQuality = 0

  /**
   * Inicializovanie treningu
   */
  public initTraining(): void {

    trainingManager.setNumberOfTasks(this.NUMBER_OF_TASKS)

  }

  /**
   * Hlavna metoda ktoru volame pri pouziti trainingovych Taskov
   * @param task - Typu Tasks, typ ulohy ktorej hodnoty sa zadavaju
   * @param valueToCount - hodnota ktora sa zadava
   */
  public countTaskValue(task: Tasks, valueToCount: number): void {

    if (!modes.isTrainingMode()) return

    if (task === Tasks.skateQuality) {

      this.allQualities.push(valueToCount)
      this.skateQuality = this.allQualities.reduce((acc, val) => (acc += val), 0) /
                (this.allQualities.length * 100)
      this.saveTaskValue(task, this.skateQuality)
      return

    }
    this.saveTaskValue(task, valueToCount)

  }

  /**
   * Hlavna privatna metoda, ktora riesi UI zmeny a trainingManager classu
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  public saveTaskValue(name: Tasks, value: number): void {

    if (!modes.isTrainingMode()) return

    value = Math.ceil(value * 10000) / 10000

    this.changeUI(name, value)
    trainingManager.setTaskValue(name, value)

  }

  /**
   * Zmena UI
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  private changeUI(name: Tasks, value: number) {

    if (!modes.isTrainingMode()) return
    console.log(`UI zmeny ${name} ma hodnotu ${value}`)
    const percent = Math.ceil(value * 100)
    const tpTask = Math.ceil(Math.round((trainingManager.getTpPerTask() * value * 10) + Number.EPSILON) / 10)
    console.log('tp task:', tpTask)

    if (tpTask !== undefined) {

      trainingState().editTask({
        text: `trainingTask5-${name}`,
        percent: String(percent),
        points: String(tpTask),
        showPoints: true
      })

    }

  }

  /**
   * Ulozenie poslednych uloh
   */
  public saveLastTasksValues(): void {

    if (!modes.isTrainingMode()) return

    this.saveTaskValue(Tasks.skateQuality, 0)

    console.log('FINAL DATA SENT')

  }

  /**
   * Reset
   */
  public reset(): void {

    // TODO ZMENIT NA TRAINING TASK
    this.skateQuality = 0
    this.allQualities = []

  }

}

export const trainingTasks = new TrainingTasks()
