<template>
  <div
    id="app"
    class="app"
  >
    <div style="z-index: 1000000">
      <div
        v-show="!modalActive && !trainingState.firstTutorialMessage"
      >
        <hud-component v-show="debugState.isHudActive" />
        <error-modal
          class="pointer-events-auto"
          @close="closeApp"
        />
        <finish-top-box
          v-if="uiState.showFinishTopBox"
          :show-first-box="finishTopBoxState.showFirstBox"
          :personal-best="finishTopBoxState.personalBest"
          :new-personal-best="finishTopBoxState.newPersonalBest"
          :world-record="finishTopBoxState.worldRecord"
          :new-world-record="finishTopBoxState.newWorldRecord"
          :first-place="finishTopBoxState.firstPlace"
          :show-second-box="finishTopBoxState.showSecondBox"
          :time="finishTopBoxState.time"
          :position="finishTopBoxState.position"
        />
        <phase-start />
        <under-menu-component />
        <time-keeper-laps-component />
        <traffic-component />
        <info-component />
        <mobile-inputs
          v-if="isMobile"
          class="pointer-events-auto"
        />
        <speed-meter-component />
        <training-layout v-if="uiState.showTrainingLayout" />
        <training-results v-if="trainingResultsState.showResults" />
        <data-table />
        <info-keys v-if="!isMobile" />
        <impulse-input
          v-if="impulseInputState.show"
        />
        <speed-bar
          v-if="runUpBarState.showBar"
        />
        <!-- <run-up-message
          v-if="runUpBarState.showBar"
        /> -->
        <black-overlay />
      </div>
      <fps-component />
      <tutorial-logic-component v-show="!modalActive" />
      <tutorial-info-keys v-show="!modalActive && !isMobile && isTutorial" />
      <menu-section
        v-if="showMenu && !trainingState.firstTutorialMessage"
        :is-mobile="isMobile"
        style="pointer-events: auto"
        :is-app-wsm="isAppWSM2021"
        :show-overlay="modalActive"
        @toggle-ui="toggleUi"
      />
      <color-overlay-component
        v-if="blurState.isActive && !blurState.isToggle"
        :opacity="0.6"
      />
      <loading-circle-overlay />
      <training-override
        style="pointer-events: auto"
        @mousedown.stop=""
        @mouseup.stop=""
        @touchstart.stop=""
        @touchend.stop=""
      />
    </div>
    <black-overlay />
    <loading />
    <white-shine-component />
  </div>
</template>

<script lang="ts">
// External Package codes

// Internal Package codes
import {
  game,
  MobileDetector,
  requestManager,
  modes,
  inputsManager
} from '@powerplay/core-minigames'
import {
  ErrorModal,
  LoadingCircleOverlay,
  ColorOverlayComponent,
  BlackOverlay,
  loadingState,
  loadingCircleState
} from '@powerplay/core-minigames-ui'

// Component codes

// Debug Components
import HudComponent from '@/components/DebugComponents/HUD.vue'
// import DirectionsInfo from '@/components/DebugComponents/DirectionsInfo.vue'

// Game Components
import MobileInputs from '@/components/Inputs/MobileInputs.vue'
import PhaseStart from '@/components/PhaseStart.vue'
import FinishTopBox from '@/components/FinishTopBox.vue'
import DataTable from '@/components/Table/DataTable.vue'
import Loading from '@/components/Loading.vue'
import InfoComponent from '@/components/InfoComponent.vue'
import MenuSection from '@/components/MenuSection/index.vue'
import FpsComponent from '@/components/FpsComponent.vue'
import SpeedMeterComponent from '@/components/SpeedMeterComponent.vue'
import TrafficComponent from '@/components/TrafficComponent.vue'
import TrainingLayout from '@/components/Training/TrainingLayout.vue'
import TrainingResults from '@/components/Training/TrainingResults.vue'
import UnderMenuComponent from '@/components/UnderMenuComponent/index.vue'
import TutorialLogicComponent from '@/components/Tutorial/TutorialLogicComponent.vue'
import TutorialInfoKeys from '@/components/Tutorial/TutorialInfoKeys.vue'
import InfoKeys from '@/components/InfoKeys.vue'
import TrainingOverride from '@/components/Training/TrainingOverride.vue'
import ImpulseInput from '@/components/ImpulseInput.vue'
import SpeedBar from '@/components/Inputs/SpeedBar.vue'
import TimeKeeperLapsComponent from '@/components/TimeKeeperComponent/TimeKeeperLapsComponent/index.vue'
import WhiteShineComponent from '../WhiteShineComponent.vue'

// Internal Codes
import { initiateListeners } from '@/helpers/initiateListeners'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { defineComponent } from 'vue'
import {
  blurState,
  type BlurState,
  debugState,
  finishTopBoxState,
  impulseInputState,
  runUpBarState,
  uiState,
  trainingState,
  trainingResultsState
} from '@/stores'

export default defineComponent({
  name: 'StructureComponent',
  components: {
    HudComponent,
    Loading,
    MobileInputs,
    PhaseStart,
    MenuSection,
    FinishTopBox,
    ErrorModal,
    DataTable,
    InfoComponent,
    SpeedMeterComponent,
    FpsComponent,
    TrafficComponent,
    TrainingLayout,
    TrainingResults,
    UnderMenuComponent,
    TutorialLogicComponent,
    BlackOverlay,
    LoadingCircleOverlay,
    TutorialInfoKeys,
    InfoKeys,
    TrainingOverride,
    ImpulseInput,
    SpeedBar,
    ColorOverlayComponent,
    TimeKeeperLapsComponent,
    WhiteShineComponent
  },
  data() {

    return {
      modalActive: false,
      isAppWSM2021: false,
      blurState: blurState(),
      debugState: debugState(),
      finishTopBoxState: finishTopBoxState(),
      impulseInputState: impulseInputState(),
      runUpBarState: runUpBarState(),
      uiState: uiState(),
      trainingState: trainingState(),
      trainingResultsState: trainingResultsState(),
    }

  },
  computed: {
    isMobile(): boolean {

      return MobileDetector.isMobile()

    },
    isTutorial(): boolean {

      return modes.isTutorial()

    },
    showMenu(): boolean {

      return !this.showLoading() && !trainingResultsState().showResults

    }
  },
  watch: {
    blurState: {
      handler(value: BlurState) {

        if (value.isActive) {

          document.getElementsByTagName('canvas')[0].classList.add('blur-class')

        } else {

          document.getElementsByTagName('canvas')[0].classList.remove('blur-class')

        }

        // osetrenie vypnutia menu pocas zobrazenej tabulky
        if (!value.isActive && value.isTable) {

          blurState().isActive = true

        }

      },
      deep: true
    },
  },
  created(): void {

    loadingState().$patch({
      showLoading: true,
      loadingProgress: 0
    })

    this.isAppWSM2021 = modes.isAppWSM2021()
    initiateListeners(this)
    tutorialUIChange.registerVM(this)

  },
  methods: {
    showLoading(): boolean {

      return loadingState().showLoading

    },
    closeApp(): void {

      disciplinePhasesManager.prematureEnded = true
      loadingCircleState().isActive = true
      requestManager.redirect(true)

    },
    toggleUi(toggle = true) {

      if (toggle) {

        inputsManager.actionInputsBlocked = true
        blurState().$patch({
          isActive: true,
          isToggle: true
        })
        this.modalActive = true
        game.pauseGame()

      } else {

        inputsManager.actionInputsBlocked = false
        this.modalActive = false
        blurState().$patch({
          isActive: false,
          isToggle: false
        })
        game.resumeGame()

      }

    }
  }
})
</script>

<style>
.blur-class {
  filter: blur(4px);
}
</style>
<style lang="less" scoped>
.app {
  min-height: 100%;
  min-width: 100%;
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.absolute {
  position: absolute;
}

.midder {
  top: 50%;
  left: 50%;
}
</style>
