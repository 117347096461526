import {
  type DisciplinePhaseManager,
  Sides,
  Tasks,
  type DisplayMessage
} from '../../types'
import {
  MobileDetector,
  fpsManager,
  modes,
  gsap
} from '@powerplay/core-minigames'
import { inputsManager } from '@/app/InputsManager'
import {
  gameConfig,
  runUpPhaseConfig,
  velocityConfig
} from '@/app/config'
import { player } from '@/app/entities/player'
import { RunUpBarManager } from '@/app/SpeedManager/RunUpBarManager'
import { trainingTasks } from '@/app/modes/training'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'
import {
  actionButtonState,
  gamePhaseState,
  inputsState,
  movementState,
  runUpBarState
} from '@/stores'

/**
 * Trieda fazy pre zjazd
 */
export class RunUpPhase implements DisciplinePhaseManager {

  /** Ci je falsed */
  public paused = false

  /** kvalita rozbehu */
  public runUpQuality = 0

  /** Ci bol dokonaly rozbeh */
  public perfectRunUp = false

  /** na ktoru stranu sa ocakava dalsi input */
  private nextImpulse?: Sides

  /** blokcker aby nestacilo drzat jednu sipku */
  private impulseBlocker?: Sides

  /** frame counter */
  private frameCounter = 0

  /** Manager baru */
  private runUpBarManager = new RunUpBarManager()

  /** blokovanie po stlaceni */
  private framesFromClick = 0

  /** ako dlho bude zobrazena hlaska na odraze */
  private MESSAGE_DURATION = 3

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Ziskanie stavu pauzed
   * @returns ci je pauzed
   */
  public getPaused(): boolean {

    return this.paused

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    this.reset()
    console.warn('run up phase started')
    gamePhaseState().showBar = true
    this.showMobileButtons()
    runUpBarState().showBar = true
    inputsState().disabled = true

  }

  /**
   * zobrazenie mobilnych buttonov
   */
  public showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    actionButtonState().$patch({
      showJoystick: true,
      isStart: false
    })
    inputsState().disabled = false

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.frameCounter += 1
    this.framesFromClick += 1

    player.speedManager.autoIncreaseRunUp()

    if (this.frameCounter >= fpsManager.fpsLimit * runUpPhaseConfig.runUpDuration) {

      this.finishPhase()
      return

    }
    let speedBarFreezeFrames = velocityConfig.speedBar.desktop.speedBarFreezeFrames

    if (MobileDetector.isMobile()) {

      speedBarFreezeFrames = velocityConfig.speedBar.mobile.speedBarFreezeFrames

    }

    if (
      this.frameCounter % velocityConfig.speedBar.speedBarAutoDecreaseFrames === 0 &&
      this.framesFromClick >= speedBarFreezeFrames
    ) {

      this.runUpBarManager.removePower(
        player.speedManager,
        velocityConfig.speedBar.speedBarAutoDecreaseValue,
        true
      )

    }
    if (this.framesFromClick <= velocityConfig.speedBar.speedBarInputFreeze) return

    this.autoMove()

    const dirLeft = inputsManager.moveDirectionLeft || movementState().positionX < 0

    const dirRight = inputsManager.moveDirectionRight || movementState().positionX > 0

    if (dirLeft && dirRight) return
    if (this.impulseBlocker === Sides.LEFT && dirLeft) return
    if (this.impulseBlocker === Sides.RIGHT && dirRight) return

    this.impulseBlocker = undefined

    if (dirLeft) {

      this.changeNextImpulse(Sides.LEFT)

    }
    if (dirRight) {

      this.changeNextImpulse(Sides.RIGHT)

    }

  }

  /**
   * auto pohyb
   */
  private autoMove(): void {

    if (!gameConfig.autoMove.isEnabled) return

    if (this.frameCounter % gameConfig.autoMove.runUpFrames) return

    this.changeNextImpulse(this.nextImpulse ?? Sides.LEFT)

  }

  /**
   * Aplikovanie kliku a zmena na dalsie slacenie impulzu
   * @param side - strana stlaceneho inputu
   */
  public changeNextImpulse(side: Sides): void {

    movementState().positionX = 0
    this.framesFromClick = 0
    this.impulseBlocker = side

    let speedBarDecreaseInput = velocityConfig.speedBar.desktop.speedBarDecreaseInput

    if (MobileDetector.isMobile()) {

      speedBarDecreaseInput = velocityConfig.speedBar.mobile.speedBarDecreaseInput

    }

    if (!this.nextImpulse) this.nextImpulse = side

    if (this.nextImpulse === side) {

      this.runUpBarManager.addPower(player.speedManager, true)

      if (this.nextImpulse === Sides.LEFT) {

        this.nextImpulse = Sides.RIGHT

      } else {

        this.nextImpulse = Sides.LEFT

      }

    } else {

      this.runUpBarManager.removePower(
        player.speedManager,
        speedBarDecreaseInput,
        true
      )

    }

    runUpBarState().nextImpulse = this.nextImpulse

  }

  /**
   * ziskame startovu spravu
   */
  private getStartingMessage(): DisplayMessage {

    const message = { message: 'poorRunUp',
      color: 2 }
    const quality = this.runUpBarManager.getBarPower()

    const { runUpQuality } = runUpPhaseConfig

    if (quality >= runUpQuality.perfect) {

      message.message = 'perfectRunUp'
      message.color = 0
      this.perfectRunUp = true

    } else if (quality >= runUpQuality.excelent) {

      message.message = 'excellentRunUp'
      message.color = 0

    } else if (quality >= runUpQuality.good) {

      message.message = 'goodRunUp'
      message.color = 1

    }

    return message

  }

  /**
   * zobrazime spravu
   */
  private showRunUpMessage(): void {

    const message = this.getStartingMessage()
    startPhaseStateManager.showStartMessageInUI(message)

    gsap.to({}, {
      duration: this.MESSAGE_DURATION,
      onComplete: () => {

        startPhaseStateManager.disableStartMessageInUI(message)

      }
    })

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    if (modes.isTrainingMode()) {

      // Zarial je to v poriadku takto, potom sam ozno zmeni
      const power = this.runUpBarManager.getBarPower() / 100

      trainingTasks.saveTaskValue(
        Tasks.startupQuality,
        power
      )

    }
    this.showRunUpMessage()
    this.runUpQuality = this.runUpBarManager.getBarPower() / 100
    player.speedManager.setMinSpeed()
    player.isStarting = false
    player.isSkating = true
    runUpBarState().showBar = false
    console.warn('run up phase ended')
    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * reset
   */
  public reset(): void {

    this.frameCounter = 0
    this.framesFromClick = 0
    this.perfectRunUp = false

  }

}
