<template>
  <div
    v-if="trainingState.firstTutorialMessage"
    class="training-override"
    :class="isMobile() ? 'mobile-training-override' : ''"
    @mousedown.prevent="unpause"
    @mouseleave.prevent=""
    @touchleave.prevent=""
    @touchstart.prevent="unpause"
  >
    <tutorial-anne />
    <tutorial-message-box
      :key="typeWrite"
      :type-write="typeWrite"
      :header-text="$t('anne')"
      :text="$t('training-text-1')"
      :button-yellow-text="yellowText"
      @show-full-text="setTypewriteToInactive"
    />
  </div>
</template>

<script lang="ts">
import {
  TutorialAnne,
  TutorialMessageBox,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { game } from '@powerplay/core-minigames'

import { defineComponent } from 'vue'
import { trainingState } from '@/stores'

export default defineComponent({
  name: 'TrainingOverride',
  components: {
    TutorialMessageBox,
    TutorialAnne
  },
  mixins: [WindowAspect],
  data() {

    return {
      typeWrite: true,
      trainingState: trainingState(),
    }

  },
  computed: {
    yellowText() {

      if (this.isMobile()) return this.$t('tapToContinue')
      return this.$t('clickToContinueTemp')

    }
  },
  methods: {
    setTypewriteToInactive() {

      this.typeWrite = false

    },
    unpause() {

      if (this.typeWrite) {

        this.typeWrite = false
        return

      }
      game.resumeGame()
      trainingState().firstTutorialMessage = false

    }
  }
})

</script>

<style lang="less" scoped>
.training-override {
    position: absolute;
    z-index: 10000000;
    top:0 ;
    left:0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    &.mobile-training-override {
        position: fixed;
    }
}
</style>
