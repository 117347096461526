<template>
  <speed-bar
    v-if="tutorialState.showBarStart"
    glow
  />
  <impulse-input
    v-if="tutorialState.showImpulseBar"
    glow
  />
</template>

<script lang="ts">
import SpeedBar from '../Inputs/SpeedBar.vue'
import ImpulseInput from '../ImpulseInput.vue'

import { defineComponent } from 'vue'
import { tutorialState } from '@/stores'

export default defineComponent({
  name: 'TutorialBars',
  components: {
    SpeedBar,
    ImpulseInput
  },
  data() {

    return {
      tutorialState: tutorialState(),
    }

  },
})

</script>

<style scoped lang="less">
.tutorial {
    .nomobile-start-bar {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 375px;
        height: 375px;
    }
}
</style>
