import { MobileDetector } from '@powerplay/core-minigames'
import { velocityConfig } from '../config'
import { SpeedManager } from './SpeedManager'
import { runUpBarState } from '@/stores'

/**
 * manager rozbehoveho baru
 */
export class RunUpBarManager {

  /** sila baru od min po max */
  private barPower = velocityConfig.speedBar.minValue

  /**
   * Getter na barPower
   * @returns Bar power
   */
  public getBarPower(): number {

    return this.barPower

  }

  /**
   * Pridanie rychlosti
   * @param speedManager - Speed manager entity
   */
  public addPower(speedManager: SpeedManager, updateUi = false): void {

    const { stepAddConf, maxValue } = velocityConfig.speedBar

    let stepAdd = velocityConfig.speedBar.desktop.stepAdd

    if (MobileDetector.isMobile()) {

      stepAdd = velocityConfig.speedBar.mobile.stepAdd

    }

    const stepAddLength = stepAddConf.length

    for (let i = 0; i < stepAddLength; i++) {

      const e = stepAddConf[i]
      if (this.barPower <= e) {

        this.barPower += stepAdd[i]
        i = stepAddLength // tzv. break

      }

    }

    if (this.barPower >= maxValue) this.barPower = maxValue

    speedManager.resolveSpeedFromBarPower(this.barPower / maxValue)
    if (!updateUi) return
    runUpBarState().speedBarValue = this.barPower

  }

  /**
   * Ubranie rychlosti
   * @param speedManager - Speed manager entity
   * @param stepRemove - Hodnota na odpocitanie
   */
  public removePower(speedManager: SpeedManager, stepRemove: number, updateUi = false): void {

    const { minValue, maxValue } = velocityConfig.speedBar

    this.barPower -= stepRemove
    if (this.barPower <= minValue) this.barPower = minValue

    speedManager.resolveSpeedFromBarPower(this.barPower / maxValue)
    if (!updateUi) return
    runUpBarState().speedBarValue = this.barPower

  }

  /** reset */
  public reset(): void {

    this.barPower = velocityConfig.speedBar.minValue

  }

}
