import { defineStore } from 'pinia'
import {
  minigameConfig,
  type SplitTimeData
} from '@powerplay/core-minigames'

export interface SplitTimeState {
  splitTimes: string[],
  actualTime: string,
  splitTimeData: SplitTimeData[],
  showFinalLap: boolean,
  showSplitTimes: boolean,
  showLeader: boolean,
  bestTime: string,
  pb: string,
  wr: number,
  finalTimes: string[],
  showFinishSplit: boolean
}

const initialState = (): SplitTimeState => ({
  splitTimes: [],
  actualTime: '',
  splitTimeData: [],
  showFinalLap: false,
  showSplitTimes: false,
  showLeader: false,
  bestTime: '',
  pb: '',
  wr: minigameConfig.dnfValue,
  finalTimes: [],
  showFinishSplit: false
})

export const splitTimeState = defineStore('splitTimeState', {
  state: initialState,
  actions: {
    addSplitTimeData(value: SplitTimeData, beforeLast: boolean) {

      if (beforeLast) {

        const length = this.splitTimeData.length
        const lastSplitTimeData = this.splitTimeData[length - 1]
        this.splitTimeData[length - 1] = value
        this.splitTimeData.push(lastSplitTimeData)

      } else {

        this.splitTimeData.push(value)

      }

    },
    addFinalTime(value: string, beforeLast: boolean) {

      if (beforeLast) {

        const length = this.finalTimes.length
        const lastFinalTimesData = this.finalTimes[length - 1]
        this.finalTimes[length - 1] = value
        this.finalTimes.push(lastFinalTimesData)

      }

      this.finalTimes.push(value)
      console.log(value, this.finalTimes)
      this.showFinishSplit = true

    },
    changeSplitTimeData() {

      const temp = this.splitTimeData[2]
      this.splitTimeData[2] = this.splitTimeData[3]
      this.splitTimeData[3] = temp

    }
  }
})
