<template>
  <div class="hud">
    <div class="info" />
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HUD',
})

</script>

<style lang="less" scoped>
.hud {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .info {
     display: flex;
     flex-direction: column;
  background: black;
  color: white;
  width:  16.666667%;
  }
}
</style>
