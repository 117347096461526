import { defineStore } from 'pinia'

export interface MovementState {
  positionX: number,
  positionY: number,
  onlyHorizontal: boolean,
  isPressed: boolean
}

const initialState = (): MovementState => ({
  positionX: 0,
  positionY: 0,
  onlyHorizontal: false,
  isPressed: false
})

export const movementState = defineStore('movementState', {
  state: initialState,
})
